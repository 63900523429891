<template>
    <div class="container-lg py-3" id="home">
        <AccueilView ref="home" id="home" />
        <ProgrammeView ref="schedule" id="schedule" />
        <AccesView ref="access" id="access" />
    </div>
</template>

<script>
import AccueilView from "./_AccueilView.vue";
import ProgrammeView from "./_ProgrammeView.vue";
import AccesView from "./_AccesView.vue";

export default {
    components: {
        AccueilView,
        ProgrammeView,
        AccesView,
    },
};
</script>
