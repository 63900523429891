<template>
    <div class="access">
        <div class="infos-content text-white position-relative">
            <h2 class="title-section">Info & Access</h2>
            <div class="container py-5">
                <div class="d-flex justify-content-lg-around flex-wrap mt-2">
                    <div class="m-4">
                        <h4 class="d-flex align-items-center text-600 pb-1">
                            <i class="fa-solid fs-2 fa-location-dot text-primary me-3"></i>
                            <span>Address</span>
                        </h4>
                        <div class="ms-5 mt-3">
                            <b>Business Village</b>
                            <br />
                            30 Rue de Montaiguillon
                            <br />
                            77560 Louan-Villegruis-Fontaine
                        </div>
                    </div>
                    <div class="m-4">
                        <h4 class="d-flex align-items-center text-600">
                            <i class="fa-solid fs-2 fa-address-book text-primary me-2"></i>
                            <span>Reference contact</span>
                        </h4>

                        <div class="d-flex flex-column ms-5">
                            <div class="mt-3">
                                <i class="fa-solid fa-user text-primary me-2--5"></i>
                                <b>Anne-Stéphanie ROBERT</b>
                            </div>
                            <div class="mt-2">
                                <i class="fa-brands fa-slack text-primary me-2--5"></i>
                                <span>DM on Slack</span>
                            </div>
                            <div class="mt-2">
                                <i class="fa-solid fa-phone text-primary me-2--5"></i>
                                <a href="tel:+33688092497">+33 688092497</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="gmap mb-3">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10547.332209352036!2d3.4863090104567984!3d48.63217667916444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ef2af727782619%3A0xf231ec2feeeda760!2sBusiness%20Village!5e0!3m2!1sfr!2sfr!4v1716763590919!5m2!1sfr!2sfr"
                width="100%"
                height="300"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>
    </div>
</template>

<script>
export default {
    created() {},
    data() {
        return {};
    },
    props: {},
    methods: {},
};
</script>
