/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import SeatingPlanView from "../views/SeatingPlanView.vue";
import NotFoundView from "../views/NotFoundView.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/seating-plan",
        name: "seating-plan",
        redirect: { name: "home" },
    },
    {
        path: "/find-my-table",
        name: "seating-plan",
        component: SeatingPlanView,
    },
    {
        path: "/404-not-found",
        name: "404",
        component: NotFoundView,
    },
    {
        path: "*",
        redirect: { name: "404" },
    },
];

const router = new VueRouter({
    mode: "history",
    base: __dirname,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const navbarHeight = document.getElementById("mynavbar").offsetHeight;
                resolve(
                    to.hash
                        ? {
                            selector: to.hash,
                            offset: { x: 0, y: navbarHeight },
                            behavior: "smooth",
                        }
                        : { x: 0, y: 0 }
                );
            }, 250);
        });
    },
});

export default router;
