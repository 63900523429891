<template>
    <div class="container-lg py-3 seating-plan" id="seating_plan">
        <div class="py-3" id="seating_plan">
            <h1>Find my table</h1>

            <div class="d-flex my-3">
                <div class="form-floating flex-fill me-2">
                    <input
                        class="form-control text-uppercase"
                        type="text"
                        v-model="searchText"
                        id="search_input"
                        name="search_input"
                        placeholder="Dupond"
                        required
                    />
                    <label for="search_input">Search your name (3 chars min.)</label>
                </div>
                <button class="btn btn-primary" v-on:click="searchMyTable">
                    <i class="fa-solid text-white fa-magnifying-glass mx-2"></i>
                </button>
            </div>

            <div v-if="searchResult.length > 0">
                <div class="accordion accordion-flush border rounded my-3 py-0--5 w-100" id="accordionResult">
                    <div v-for="item in searchResult" v-bind:key="item.id" class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`heading${item.id}`">
                            <button
                                class="accordion-button collapsed d-flex flex-wrap align-items-baseline"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#${item.id}`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapse${item.id}`"
                            >
                                <span class="text-nowrap me-2">{{ item.displayname }}</span>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`${item.id}`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`heading${item.id}`"
                            data-bs-parent="#accordionResult"
                        >
                            <div class="accordion-body text-italic d-flex flex-column">
                                <div>
                                    <div class="small text-primary fw-medium">Dinner 1 - June 4th</div>
                                    <div>{{ item.day1 }}</div>
                                </div>
                                <hr />
                                <div>
                                    <div class="small text-primary fw-medium">Dinner 2 - June 5th</div>
                                    <div>{{ item.day2 }}</div>
                                </div>
                                <hr />
                                <div>
                                    <div class="small text-primary fw-medium">Dinner 3 - June 6th</div>
                                    <div>{{ item.day3 }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="noResult" class="text-primary fw-bold">No results</div>
        </div>
    </div>
</template>

<script>
import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";

const findMyTable = httpsCallable(functions, "findMyTable");

export default {
    components: {},
    data() {
        return {
            searchText: "",
            searchResult: [],
            noResult: false,
        };
    },
    methods: {
        async searchMyTable() {
            this.searchResult = [];
            if (this.searchText.length >= 3) {
                await findMyTable({ search: this.searchText })
                    .then((result) => {
                        const data = result.data;
                        this.searchResult = data;
                    })
                    .catch(function (error) {
                        console.error("Une erreur est survenue", error.code, error.message, error.details);
                    });

                this.noResult = this.searchResult.length == 0;
            }
        },
    },
};
</script>
