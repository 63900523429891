<template>
    <div id="app">
        <div v-if="dev_mode" class="position-fixed" style="top: 10px; left: 10px; z-index: 9999">
            <span class="badge bg-primary d-block d-sm-none">xs</span>
            <span class="badge bg-secondary d-none d-sm-block d-md-none">sm</span>
            <span class="badge bg-success d-none d-md-block d-lg-none">md</span>
            <span class="badge bg-dark d-none d-lg-block d-xl-none">lg</span>
            <span class="badge bg-info d-none d-xl-block">xl</span>
        </div>
        <nav class="navbar navbar-expand-md navbar-light py-3 shadow" id="mynavbar">
            <div class="container-lg">
                <router-link to="/#home" class="navbar-brand">
                    <img src="./assets/img/logo.png" class="me-3 me-md-0" alt="" />
                </router-link>

                <button
                    class="navbar-toggler ms-auto"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNavAltMarkup"
                    aria-controls="navbarNavAltMarkup"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse text-center" id="navbarNavAltMarkup">
                    <ul class="navbar-nav mx-auto d-flex-center">
                        <li class="mx-3">
                            <router-link to="/#home" class="nav-link active">
                                <i class="fa-solid fa-house"></i>
                                <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Home</span>
                            </router-link>
                        </li>
                        <li class="mx-3">
                            <router-link to="/#schedule" class="nav-link active">
                                <i class="fa-solid fa-clipboard-list"></i>
                                <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Schedule</span>
                            </router-link>
                        </li>
                        <li class="mx-3">
                            <router-link to="/#access" class="nav-link active">
                                <i class="fa-solid fa-route"></i>
                                <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                                    >Info & Access</span
                                >
                            </router-link>
                        </li>
                        <li class="mx-3">
                            <router-link to="/find-my-table" class="nav-link active">
                                <i class="fa-solid fa-magnifying-glass"></i>
                                <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">My table</span>
                            </router-link>
                        </li>
                    </ul>
                </div>

                <router-link to="/#home" class="navbar-brand invisible d-none d-md-block">
                    <img src="./assets/img/logo.png" class="me-3 me-md-0" alt="" />
                </router-link>
            </div>
        </nav>

        <router-view ref="view" class="flex-fill body-container"></router-view>

        <footer class="px-5">
            <div class="row no-gutters mx-0 my-3">
                <div class="col small">
                    Created by
                    <a
                        target="_blank"
                        class="text-nowrap"
                        href="https://www.agence-evenementielle-innovevents.fr/reseau-evenementiel/paris/"
                        >INNOV'events Paris</a
                    >
                    ©{{ new Date().getFullYear() }}
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
export default {
    data() {
        return {
            dev_mode: process.env.NODE_ENV == "development",
        };
    },
};
</script>
