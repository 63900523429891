<template>
    <div class="program pt-3 mb-3 mb-md-5--5">
        <h2 class="title-section">Schedule</h2>

        <div class="d-flex-center flex-column container-md">
            <div
                class="d-flex justify-content-center align-items-center gap-3 gap-sm-4 gap-md-5 program__day-selector flex-wrap my-3 my-md-5"
            >
                <span v-on:click="selectDay(1)" v-bind:class="selectedDay == 1 ? 'selected' : ''">June 4th</span>
                <span v-on:click="selectDay(2)" v-bind:class="selectedDay == 2 ? 'selected' : ''">June 5th</span>
                <span v-on:click="selectDay(3)" v-bind:class="selectedDay == 3 ? 'selected' : ''">June 6th</span>
                <span v-on:click="selectDay(4)" v-bind:class="selectedDay == 4 ? 'selected' : ''">June 7th</span>
            </div>

            <table class="program__table w-100" v-if="selectedDay == 1">
                <tr class="fw-bold text-primary fs-4">
                    <th>TIME</th>
                    <th>
                        <div class="d-flex justify-content-between flex-column flex-md-row">
                            <span>AGENDA</span>
                            <span><span class="d-md-none">/&nbsp;</span>MEETING POINTS</span>
                        </div>
                    </th>
                </tr>
                <tr>
                    <td colspan="2" class="separator">Breakfast</td>
                </tr>
                <tr>
                    <td class="time">
                        <span>01:00pm</span>
                        <br class="d-md-none" />
                        <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                        <span>01:45pm</span>
                    </td>
                    <td>
                        <div class="d-flex justify-content-between flex-column flex-md-row">
                            <span class="fw-medium">Guests arrival</span>
                            <span class="">
                                <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                <span class="text-primary">Business Village</span>
                            </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="separator">Lunch</td>
                </tr>
                <tr>
                    <td class="time">
                        <span>01:45pm</span>
                        <br class="d-md-none" />
                        <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                        <span>02:45pm</span>
                    </td>
                    <td>
                        <div class="d-flex justify-content-between flex-column flex-md-row">
                            <span class="fw-medium">Buffet Lunch</span>
                            <span class="">
                                <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                <span class="text-primary">Pavillon Blanc</span>
                            </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="separator">Kenote</td>
                </tr>
                <tr>
                    <td class="time">
                        <span>03:00pm</span>
                        <br class="d-md-none" />
                        <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                        <span>05:00pm</span>
                    </td>
                    <td>
                        <div class="d-flex justify-content-between flex-column flex-md-row">
                            <span class="fw-medium">Keynote</span>
                            <span class="">
                                <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                <span class="text-primary">Plenary room (Meeting Suite)</span>
                            </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="separator">Free Time</td>
                </tr>
                <tr>
                    <td class="time">
                        <span>05:00pm</span>
                        <br class="d-md-none" />
                        <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                        <span>06:00pm</span>
                    </td>
                    <td>
                        <div class="d-flex justify-content-between flex-column flex-md-row">
                            <span class="fw-medium">Free Time</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="separator">Activities</td>
                </tr>
                <tr class="border-bottom">
                    <td class="time">
                        <span>06:00pm</span>
                        <br class="d-md-none" />
                        <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                        <span>08:00pm</span>
                    </td>
                    <td>
                        <div class="d-flex justify-content-between flex-column flex-md-row">
                            <span class="fw-medium">Collaborative Painting</span>
                            <span class="">
                                <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                <span class="text-primary">
                                    Outside, in front of <span class="text-nowrap">the lobby</span>
                                </span>
                            </span>
                        </div>
                    </td>
                </tr>
                <tr class="border-bottom">
                    <td class="time">
                        <span>06:00pm</span>
                        <br class="d-md-none" />
                        <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                        <span>06:45pm</span>
                    </td>
                    <td>
                        <div class="d-flex justify-content-between flex-column flex-md-row">
                            <span class="fw-medium">Yoga class</span>
                            <span class="">
                                <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                <span class="text-primary">Outside</span>
                            </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="time">
                        <span>07:00pm</span>
                        <br class="d-md-none" />
                        <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                        <span>07:45pm</span>
                    </td>
                    <td>
                        <div class="d-flex justify-content-between flex-column flex-md-row">
                            <span class="fw-medium">Yoga class</span>
                            <span class="">
                                <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                <span class="text-primary">Outside</span>
                            </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="separator">Seated Diner</td>
                </tr>
                <tr>
                    <td class="time">
                        <span>08:00pm</span>
                        <br class="d-md-none" />
                        <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                        <span>10:00pm</span>
                    </td>
                    <td>
                        <div class="d-flex justify-content-between flex-column flex-md-row">
                            <span class="fw-medium">Seated Diner</span>
                            <span class="">
                                <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                <span class="text-primary">Pavillon Blanc</span>
                            </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="separator">Karaoke</td>
                </tr>
                <tr class="border-bottom">
                    <td class="time">
                        <span>10:00pm</span>
                        <br class="d-md-none" />
                        <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                        <span>12:00am</span>
                    </td>
                    <td>
                        <div class="d-flex justify-content-between flex-column flex-md-row">
                            <span class="fw-medium">Karaoke</span>
                            <span class="">
                                <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                <span class="text-primary">Harvard</span>
                            </span>
                        </div>
                    </td>
                </tr>
            </table>

            <div v-if="selectedDay == 2">
                <table class="program__table w-100">
                    <tr class="fw-bold text-primary fs-4">
                        <th>TIME</th>
                        <th>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span>AGENDA</span>
                                <span><span class="d-md-none">/&nbsp;</span>MEETING POINTS</span>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <td colspan="2" class="separator">Breakfast</td>
                    </tr>
                    <tr>
                        <td class="time">
                            <span>07:00am</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>09:00am</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Breakfast</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Stanford Room</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="separator">Plenary Session</td>
                    </tr>
                    <tr>
                        <td class="time">
                            <span>09:00am</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>11:00am</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Plenary Session</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Plenary room (Meeting Suite)</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="separator">Corporate pictures session*</td>
                    </tr>
                    <tr>
                        <td class="time">
                            <span>11:00am</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>07:00pm</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Corporate pictures session</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">TBC</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="separator">Activities</td>
                    </tr>
                    <tr class="border-bottom">
                        <td class="time">
                            <span>11:00am</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>01:00pm</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Business Olympiades</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Outside</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr class="border-bottom">
                        <td class="time">
                            <span>11:00am</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>06:00pm</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">
                                    A day in Fontainebleau, including a picnic.
                                    <br />
                                    <small>
                                        Discover the Château de Fontainebleau and the wonders it contains, as well as
                                        the nearby courtyards and gardens.
                                    </small>
                                </span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Outside, in front of the lobby</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr class="border-bottom">
                        <td class="time">
                            <span>09:00am</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>12:00am</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Karaoke</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Harvard</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr class="border-bottom">
                        <td class="time">
                            <span>11:00am</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>11:45am</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Yoga</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Outside</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="time">
                            <span>12:00pm</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>12:45pm</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Yoga</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Outside</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="separator">Lunch</td>
                    </tr>
                    <tr>
                        <td class="time">
                            <span>01:00pm</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>02:30pm</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Buffet Lunch</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Pavillon Blanc</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="separator">Activities</td>
                    </tr>
                    <tr>
                        <td class="time">
                            <span>02:30pm</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>05:00pm</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Business Olympiades</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Outside</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="separator">Champagne Tasting & Mixology</td>
                    </tr>
                    <tr class="border-bottom">
                        <td class="time">
                            <span>06:00pm</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>06:45pm</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Champagne Tasting</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Pavillon Harvard</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr class="border-bottom">
                        <td class="time">
                            <span>07:00pm</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>07:45pm</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Champagne Tasting</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Pavillon Harvard</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="time">
                            <span>06:00pm</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>07:00pm</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Mixology</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Harvard 1 ou 2</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="separator">Diner</td>
                    </tr>
                    <tr>
                        <td class="time">
                            <span>08:00pm</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>10:00pm</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Seated Dinner</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Pavillon Blanc</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="separator">Karaoke</td>
                    </tr>
                    <tr>
                        <td class="time">
                            <span>10:00pm</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>12:00am</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Karaoke</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Harvard</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="separator">Show & Live Band</td>
                    </tr>
                    <tr class="border-bottom">
                        <td class="time">
                            <span>10:00pm</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>10:30pm</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Fire breathers Show</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Outside</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr class="border-bottom">
                        <td class="time">
                            <span>10:00pm</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>01:00am</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Live Band</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Pavillon Harvard</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                </table>

                <div class="text-primary small mt-3">
                    *On Wednesday, the 5th, and Thursday, the 6th, from 11 AM to 7 PM, a photographer will be available
                    onsite (room TBD). Feel free to get your picture taken whenever you are free during these times.
                </div>
            </div>
            <div v-if="selectedDay == 3">
                <table class="program__table w-100">
                    <tr class="fw-bold text-primary fs-4">
                        <th>TIME</th>
                        <th>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span>AGENDA</span>
                                <span><span class="d-md-none">/&nbsp;</span>MEETING POINTS</span>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <td colspan="2" class="separator">BREAKFAST</td>
                    </tr>
                    <tr>
                        <td class="time">
                            <span>07:00am</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>09:00am</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Breakfast</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Stanford Room</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="separator">Round Tables Session</td>
                    </tr>
                    <tr>
                        <td class="time">
                            <span>09:00am</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>10:45am</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Round Tables session</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">TBC</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="separator">Corporate pictures session*</td>
                    </tr>
                    <tr>
                        <td class="time">
                            <span>11:00am</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>07:00pm</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Corporate pictures session</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">TBC</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="separator">Team Time</td>
                    </tr>
                    <tr>
                        <td class="time">
                            <span>11:00am</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>01:00pm</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Team Time</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">TBC</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="separator">GTM Workshop</td>
                    </tr>
                    <tr>
                        <td class="time">
                            <span>11:00am</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>01:00pm</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">GTM Workshop</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Meeting Suite</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="separator">Activities</td>
                    </tr>
                    <tr class="border-bottom">
                        <td class="time">
                            <span>11:00am</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>11:45am</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Yoga</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Outside</span>
                                </span>
                            </div>
                        </td>
                    </tr>

                    <tr class="border-bottom">
                        <td class="time">
                            <span>12:00pm</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>12:45pm</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Yoga</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Outside</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="time">
                            <span>09:00am</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>12:00am</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Karaoke</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Harvard</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="separator">Lunch</td>
                    </tr>
                    <tr>
                        <td class="time">
                            <span>01:00pm</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>02:30pm</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Buffet Lunch</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Stanford Room</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="separator">Team Time</td>
                    </tr>
                    <tr>
                        <td class="time">
                            <span>02:30pm</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>07:00pm</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Team Time</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">TBC</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="separator">Activities</td>
                    </tr>
                    <tr class="border-bottom">
                        <td class="time">
                            <span>03:00pm</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>06:00pm</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">
                                    Trip to Provins
                                    <br />
                                    <small
                                        >Set off to discover one of the most beautiful fortified towns in France.</small
                                    >
                                </span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Outside, in front of the lobby</span>
                                </span>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td class="time">
                            <span>03:00pm</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>06:00pm</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">
                                    Kart & Run
                                    <br />
                                    <small>Customize your pedal kart before taking off in a relay race.</small>
                                </span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Outside</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="separator">Falconry show</td>
                    </tr>
                    <tr>
                        <td class="time">
                            <span>07:00pm</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>08:00pm</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Falconry show</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Outside</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="separator">DINNER</td>
                    </tr>
                    <tr>
                        <td class="time">
                            <span>08:00pm</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>10:00pm</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Seated Dinner</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Stanford Room</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="separator">Party with DJ</td>
                    </tr>
                    <tr class="border-bottom">
                        <td class="time">
                            <span>10:00pm</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>12:00am</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Fluorescent makeup Bar</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Terrace</span>
                                </span>
                            </div>
                        </td>
                    </tr>

                    <tr class="border-bottom">
                        <td class="time">
                            <span>10:00pm</span>
                            <br class="d-md-none" />
                            <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                            <span>04:00am</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-between flex-column flex-md-row">
                                <span class="fw-medium">Open bar and dancing with DJ</span>
                                <span class="">
                                    <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                    <span class="text-primary">Terrace</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                </table>
                <div class="text-primary small mt-3">
                    *On Wednesday, the 5th, and Thursday, the 6th, from 11 AM to 7 PM, a photographer will be available
                    onsite (room TBD). Feel free to get your picture taken whenever you are free during these times.
                </div>
            </div>
            <table class="program__table w-100" v-if="selectedDay == 4">
                <tr class="fw-bold text-primary fs-4">
                    <th>TIME</th>
                    <th>
                        <div class="d-flex justify-content-between flex-column flex-md-row">
                            <span>AGENDA</span>
                            <span><span class="d-md-none">/&nbsp;</span>MEETING POINTS</span>
                        </div>
                    </th>
                </tr>
                <tr>
                    <td colspan="2" class="separator">Brunch</td>
                </tr>
                <tr>
                    <td class="time">
                        <span>07:00am</span>
                        <br class="d-md-none" />
                        <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                        <span>01:00pm</span>
                    </td>
                    <td>
                        <div class="d-flex justify-content-between flex-column flex-md-row">
                            <span class="fw-medium">Brunch</span>
                            <span class="">
                                <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                <span class="text-primary">Stanford Room</span>
                            </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="py-1"></td>
                </tr>
                <tr>
                    <td colspan="2" class="separator bg-secondary fs-6">DEPARTURES ALL DAY (till 2:00pm)</td>
                </tr>
                <tr>
                    <td colspan="2" class="py-1"></td>
                </tr>
                <tr>
                    <td colspan="2" class="separator">Wrap-up</td>
                </tr>
                <tr>
                    <td class="time">
                        <span>10:30am</span>
                        <br class="d-md-none" />
                        <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                        <span>11:00am</span>
                    </td>
                    <td>
                        <div class="d-flex justify-content-between flex-column flex-md-row">
                            <span class="fw-medium">Closing Speech</span>
                            <span class="">
                                <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                <span class="text-primary">Plenary room (Meeting Suite)</span>
                            </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="separator">Activities</td>
                </tr>
                <tr class="border-bottom">
                    <td class="time">
                        <span>11:00am</span>
                        <br class="d-md-none" />
                        <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                        <span>11:45am</span>
                    </td>
                    <td>
                        <div class="d-flex justify-content-between flex-column flex-md-row">
                            <span class="fw-medium">Yoga</span>
                            <span class="">
                                <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                <span class="text-primary">TBC</span>
                            </span>
                        </div>
                    </td>
                </tr>
                <tr class="border-bottom">
                    <td class="time">
                        <span>12:00pm</span>
                        <br class="d-md-none" />
                        <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                        <span>12:45pm</span>
                    </td>
                    <td>
                        <div class="d-flex justify-content-between flex-column flex-md-row">
                            <span class="fw-medium">Yoga</span>
                            <span class="">
                                <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                <span class="text-primary">TBC</span>
                            </span>
                        </div>
                    </td>
                </tr>
                <tr class="border-bottom">
                    <td class="time">
                        <span>11:00am</span>
                        <br class="d-md-none" />
                        <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                        <span>11:30am</span>
                    </td>
                    <td>
                        <div class="d-flex justify-content-between flex-column flex-md-row">
                            <span class="fw-medium">Aquagym</span>
                            <span class="">
                                <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                <span class="text-primary">Pool</span>
                            </span>
                        </div>
                    </td>
                </tr>
                <tr class="border-bottom">
                    <td class="time">
                        <span>11:30am</span>
                        <br class="d-md-none" />
                        <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                        <span>12:00pm</span>
                    </td>
                    <td>
                        <div class="d-flex justify-content-between flex-column flex-md-row">
                            <span class="fw-medium">Aquagym</span>
                            <span class="">
                                <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                <span class="text-primary">Pool</span>
                            </span>
                        </div>
                    </td>
                </tr>
                <tr class="border-bottom">
                    <td class="time">
                        <span>12:00pm</span>
                        <br class="d-md-none" />
                        <i class="fa-solid fa-caret-right text-primary me-1 ms-md-1" />
                        <span>12:30pm</span>
                    </td>
                    <td>
                        <div class="d-flex justify-content-between flex-column flex-md-row">
                            <span class="fw-medium">Aquagym</span>
                            <span class="">
                                <i class="fa-solid fa-location-dot me-2 text-secondary-light mt-2 mt-md-0" />
                                <span class="text-primary">Pool</span>
                            </span>
                        </div>
                    </td>
                </tr>
            </table>

            <div class="bg-primary text-white py-3 px-4 rounded-5 small fw-bold mt-3">
                Available onsite : Pétanque, table tennis, beach volleyball, table soccer, football, pool etc.
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            selectedDay: 1,
        };
    },
    methods: {
        selectDay(d) {
            this.selectedDay = d;
        },
    },
};
</script>
