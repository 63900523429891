<template>
    <div class="home">
        <div class="home__image d-flex justify-content-center justify-content-md-between flex-column">
            <h1 class="home__title my-5 d-flex flex-column justify-content-around flex-wrap relative">
                <span class="text-uppercase mx-4">Global</span>
                <span class="text-uppercase mx-4">Offsite</span>
                <span class="text-uppercase mx-4">2024.</span>

                <img
                    src="../assets/img/logo-icon.png"
                    class="home__title__logo order-0 order-md-1 p-2 rounded-1 align-self-end align-self-md-start"
                    alt=""
                />
            </h1>
            <div class="cab mb-3 me-4 d-none d-md-block">
                <MyCountdown :date="countdown_end" />
            </div>
        </div>

        <div class="home__description container d-flex-center flex-column py-5">
            <MyCountdown class="d-sm-block d-md-none phonemode align-self-stretch" :date="countdown_end"></MyCountdown>

            <div class="container">
                <div class="separation"></div>

                <div class="mt-5 text-center">
                    <p>
                        This year, <b class="text-primary">Pigment</b> is taking everyone to a location just 1.5 hours
                        away by bus to the east of Paris for an unforgettable experience where we'll connect,
                        collaborate, and celebrate our achievements.
                    </p>

                    <p>
                        Can't wait to share unforgettable moments with you all at
                        <b class="text-primary">Business Village</b>!
                    </p>
                </div>

                <div class="separation"></div>
            </div>
        </div>
    </div>
</template>

<script>
import MyCountdown from "@/components/MyCountdown.vue";

export default {
    components: {
        MyCountdown,
    },
    data() {
        return {
            countdown_end: new Date("2024-06-04T13:00:00"),
        };
    },
};
</script>
